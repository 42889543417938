<template>
  <div class="documents-with-title">
    <h2 class="text-24 font-semibold mb-80" v-html="title" />
    <documents-and-links-wrapper
      :documents="documents"
      :links="links"
      :document-link-text="$t('SHARED.MORE_INFORMATION')"
    />
  </div>
</template>

<script>
export default {
  name: 'DocumentsWithTitle',
  components: {
    DocumentsAndLinksWrapper: () => import('@/components/DocumentsAndLinksWrapper.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    links: {
      type: Array,
    },
  },
}
</script>
